<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/lawapply_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block60"></div>
		<!-- 服务简介 -->
		<div class="huaxue_new_type_box w1239">
			<div class="type_name fontsize38">维权援助在线申请</div>
			<div class="type_desc fontsize14">ONLINE APPLICATION FOR ADVOCACY ASSISTANCE</div>
		</div>
		<div class="block40"></div>
		<!-- 表单填写 -->
		<div class="huaxue_apply_form_box w1239">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth" class="demo-ruleForm">
			    <el-form-item label="申请人姓名" prop="name">
			      <el-input v-model="ruleForm.name" placeholder="请输入内容"></el-input>
			    </el-form-item>
				<el-form-item label="申请人电话" prop="phone">
				  <el-input v-model="ruleForm.phone" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="申请人地址">
				    <div>
				        <el-cascader
				            size="large"
				            :options="options"
				            v-model="areaOptions"
				            @change="handleChangeArea"
				        >
				        </el-cascader>
				    </div>
				</el-form-item>
				<el-form-item label="详细地址" prop="name">
				  <el-input v-model="ruleForm.address.address" placeholder="请输入内容"></el-input>
				</el-form-item>
			    <el-form-item label="申请维权援助事项">
			      <el-input type="textarea" v-model="ruleForm.contents" placeholder="请输入内容"></el-input>
			    </el-form-item>
				<el-form-item label="涉及权利类别" prop="name">
				    <el-checkbox-group v-model="categoryInvolvedList">
				        <el-checkbox label="专利">专利</el-checkbox>
				        <el-checkbox label="商标">商标</el-checkbox>
						<el-checkbox label="版权">版权</el-checkbox>
						<el-checkbox label="地理标志">地理标志</el-checkbox>
						<el-checkbox label="商业机密">商业机密</el-checkbox>
						<el-checkbox label="植物新品种">植物新品种</el-checkbox>
						<el-checkbox label="集成电路布图设计">集成电路布图设计</el-checkbox>
						<el-checkbox label="其他">其他</el-checkbox>
				    </el-checkbox-group>
				</el-form-item>
				<el-form-item label="维权援助申请人类别" prop="userType">
				    <el-select v-model="ruleForm.userType" placeholder="请选择">
				      <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.name" />
				    </el-select>
			    </el-form-item>
				<el-form-item label="受理维权中心" prop="centerType">
				    <el-select v-model="ruleForm.centerType" placeholder="请选择">
				      <el-option v-for="(item, index) in typeList1" :key="index" :label="item.name" :value="item.name" />
				    </el-select>
				</el-form-item>
				<el-form-item>
			        <el-button type="primary" @click="addSubmit('ruleForm')">立即提交申请</el-button>
			    </el-form-item>
			</el-form>
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import { regionData, CodeToText ,TextToCode} from "element-china-area-data";
export default {
	data() {
		return {
			formLabelWidth:"158px",
			ruleForm:{
				contents:"",
				name:"",
				phone:"",
				categoryInvolved:'',
				centerType:'',
				userType:"",
				address: {
				    address: "",
				    area: "",
				    city: "",
				    province: ""
				}
			},
			categoryInvolvedList:[],//涉及权利类别，多选
			rules: {
				name: [
				    { required: true, message: '请输入名称', trigger: 'change' }
				],
				phone: [
				    { required: true, message: '请输入名称', trigger: 'change' }
				],
				contents: [
				    { required: true, message: '请输入名称', trigger: 'change' }
				],
				categoryInvolved: [
				    { required: true, message: '请输入', trigger: 'change' }
				],
				centerType: [
				    { required: true, message: '请输入', trigger: 'change' }
				],
				userType: [
				    { required: true, message: '请输入', trigger: 'change' }
				],
				
			},
			options: regionData,
			areaOptions: [],//区域省市区
			typeList:[],//维权援助申请人类别
			typeList1:[],//维权援助维权中心
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(13).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		//获取维权援助申请人类别
		this.assistanceApplyType()
		//维权援助维权中心
		this.assistanceApplyCenter()

	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		// 添加提交
		addSubmit(form) {
			var _this = this
			if(this.categoryInvolvedList.length<=0){
				this.$message.error("至少选择一个涉及权利类别")
				return;
			}
			//涉及权利类别
			this.ruleForm.categoryInvolved = this.categoryInvolvedList.join("，")
			this.$refs[form].validate(valid => {
				if (valid) {
					var params = _this.ruleForm;
					_this.$http.post('frontEnd/law/assistanceApply', params).then(function(res) {
						//数据处理
						if (res.code == 200) {
							_this.$confirm("提交成功,请勿重复提交~", '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
							_this.$refs[form].resetFields();
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
				} else {
					this.$message.error('请填写必填数据');
					return false;
				}
			});
		},
		resetForm(form) {
			this.$refs[form].resetFields();
		},
		//区域省市区
		handleChangeArea() {
		    let loc = [];
			console.log(this.areaOptions)
		    for (let i = 0; i < this.areaOptions.length; i++) {
		       loc.push(CodeToText[this.areaOptions[i]])
		    }
		    this.ruleForm.address.province = loc[0]
			this.ruleForm.address.city = loc[1]
			this.ruleForm.address.area = loc[2]
		},
		//获取维权援助申请人类别
		assistanceApplyType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('frontEnd/law/assistanceApplyType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.typeList = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//维权援助维权中心
		assistanceApplyCenter() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 88,
			};
			this.$http.post('frontEnd/law/assistanceApplyCenter', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					if(records.length>0){
						_this.typeList1 = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">
.el-radio{
	margin-top: 8px;
}
</style>
